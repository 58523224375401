import React from "react";
import { ThemeContext } from "../../context/themeContext";
import { useContext } from "react";
import { MdOutlineArrowOutward } from "react-icons/md";

function Footer() {
  const { theme } = useContext(ThemeContext);

  function SocialButton({ name, Icon, href }) {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        href={href}
        className="social-button text"
      >
        <div className="social-button-name">{name}</div>
        <MdOutlineArrowOutward className="social-icon" />
      </a>
    );
  }

  return (
    <div className={theme}>
      <div className="footer">
        <div className="contact-title">Contact</div>
        <div className="interested text">
          Interested in working with me or perhaps just talk{" "}
          <span className="question-mark">?</span>
        </div>
        <div className="send-email text">
          If you wanna get in touch, talk to me about a project collaboration or
          just say hi, send an email to{" "}
          <span className="email">dev1mouad@gmail.com</span> and ~let's talk.
        </div>
        <div className="text social-row">
          <div className="socials">
            <SocialButton name={"Github"} href={"https://github.com/Mouadzz"} />

            <SocialButton
              name={"Linkedin"}
              href={"https://www.linkedin.com/in/mouaad-lasritene-602680202/"}
            />
          </div>
          <div className="text crafted-by-me">
            Handcrafted by me<span className="copyright"> © </span>2024
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
