import React, { createContext, useState, useEffect } from "react";

export const ThemeContext = createContext({ theme: "light" });

export const ThemeProvider: React.FC<{}> = ({ children }) => {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)")
      .matches;
    setTheme(prefersDarkMode ? "dark" : "light");
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
