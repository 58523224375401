import { ThemeContext } from "./context/themeContext";
import { useContext } from "react";
import Navbar from "./components/navbar/navbar";
import About from "./components/about/about";
import Experience from "./components/experience/experience";
import Footer from "./components/footer/footer";
import Projects from "./components/projects/projects";

function App() {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={theme}>
      <div className="background">
        <div id="body" className="container">
          <Navbar />
          <About />
          <Experience />
          <Projects />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
