import React from "react";
import { ThemeContext } from "../../context/themeContext";
import { useContext, useState } from "react";
import data from "./data.json";
import graitor from "../../assets/graitor.jpeg";
import sportime from "../../assets/sportime.webp";
import { FaAppStore, FaGooglePlay, FaGithub } from "react-icons/fa";
import { IoMdArrowForward } from "react-icons/io";

const logos = [graitor, sportime, sportime];

function LinkButton({ Icon, text, url }) {
  return (
    <a href={url} target="_blank" rel="noreferrer" className="link-button">
      <Icon className="link-icon" />
      <div>{text}</div>
    </a>
  );
}

function ExperienceItem({ exp, index }) {
  return (
    <div className="experience-item">
      <div className="experience-date">
        {exp["start_date"]}&nbsp;-&nbsp;{exp["end_date"]}
        &nbsp;:&nbsp; {exp["duration"]}
      </div>
      <div className="experience-title text"> {exp["job_title"]}</div>
      <div className="experience-subtitle">
        <div className="experience-company">
          <img className="experience-logo" src={logos[index]} alt="" />
          {exp["company_name"]}
        </div>{" "}
        &nbsp;:&nbsp;
        {exp["location"]}&nbsp;-&nbsp;
        {exp["location_type"]}
      </div>
      <p className="experience-description text">{exp["description"]}</p>
      <div className="experience-links">
        {exp["github"] != null ? (
          <LinkButton url={exp["github"]} text="Github" Icon={FaGithub} />
        ) : null}

        {exp["app-store"] != null ? (
          <LinkButton
            url={exp["app-store"]}
            text="App Store"
            Icon={FaAppStore}
          />
        ) : null}

        {exp["google-play"] != null ? (
          <LinkButton
            url={exp["google-play"]}
            text="Google Play"
            Icon={FaGooglePlay}
          />
        ) : null}
      </div>
    </div>
  );
}

function Experience() {
  const { theme } = useContext(ThemeContext);
  const [showAll, setShowAll] = useState(false);

  const handleClick = () => {
    setShowAll(!showAll);
  };

  return (
    <div className={theme}>
      <div className="experience">
        <div className="section-title text">Professional Experience</div>
        {showAll ? data.map((exp, index) => (
          <ExperienceItem exp={exp} index={index} />
        )) : data.slice(0, 2).map((exp, index) => (
          <ExperienceItem exp={exp} index={index} />
        ))}
        {!showAll && (
          <div className="view-all-button text" onClick={handleClick}>
            <div className="view-all-button-text">View all experiences</div>
            <IoMdArrowForward className="view-all-button-icon" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Experience;
